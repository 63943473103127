<template>
  <div>
    <button @click="uploadFile" class="green-button">Upload File</button>
    <button @click="downloadFile" class="green-button">Download File</button>
  </div>
</template>

<script>
export default {
  name: "UploadAndDownload",
  props: {
    file: File,
  },
  data() {
    return {
      isDownloadLinkUnavailable: true,
      requestedFileLink: null,
      fileSizeInBytes: 0,
      estimated_processed_time: 0
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_LOCAL === 'true'
        ? 'http://localhost:8900'
        : 'https://www.druggableprotein.com';
    },
  },
  created() {
    console.log(this.baseUrl);
  },
  methods: {
    async uploadFile() {
      if (!this.file) {
        alert("No file selected.");
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);
      this.fileSizeInBytes = this.file.size;
      const now = new Date();
      const uploadTime = now.getFullYear().toString().padStart(4, '0') + '-' +
                         (now.getMonth() + 1).toString().padStart(2, '0') + '-' +
                         now.getDate().toString().padStart(2, '0') + ' ' +
                         now.getHours().toString().padStart(2, '0') + ':' +
                         now.getMinutes().toString().padStart(2, '0') + ':' +
                         now.getSeconds().toString().padStart(2, '0') + '.' +
                         now.getMilliseconds().toString().padStart(3, '0');
      console.log("upload time is", uploadTime)
      console.log("file size in b is :", this.fileSizeInBytes);
      console.log("Estimated time in seconds:", this.fileSizeInBytes / 10);
      this.estimated_processed_time = this.fileSizeInBytes / 10;
      formData.append('uploadTime', uploadTime);
      const [datePart, timePart] = uploadTime.split(' ');
      const [year, month, day] = datePart.split('-');
      const [hour, minute, secondWithMilliseconds] = timePart.split(':');
      const [second] = secondWithMilliseconds.split('.');
      this.requestedFileLink = `result_${year}-${month}-${day}_${hour}-${minute}-${second}.zip`;      
      console.log(this.requestedFileLink);
      try {
        const response = await fetch(`${this.baseUrl}/upload`, {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const result = await response.json();
          result.message = result.message + ". Estimated time is 10 seconds.";
          alert(result.message);
          this.isDownloadLinkUnavailable = false;
        } else {
          alert("Failed to upload file.");
          this.isDownloadLinkUnavailable = true;
        }
      } catch (error) {
        console.error("Error during file upload:", error);
        alert("Error during file upload.");
        this.isDownloadLinkUnavailable = true;
      }
    },
    async downloadFile() {
      if (this.isDownloadLinkUnavailable || !this.requestedFileLink) {
        alert("File not available for download.");
        return;
      }

      try {
        const response = await fetch(`${this.baseUrl}/static/processed/${this.requestedFileLink}`, {
          method: 'HEAD',
        });

        if (response.ok) {
          window.location.href = `${this.baseUrl}/static/processed/${this.requestedFileLink}`;
        } else {
          alert("File is still processing. Please try downloading again later.");
        }
      } catch (error) {
        console.error("Error during file check:", error);
        alert("An error occurred while checking the file. Please try again.");
      }
    },
  },
};

</script>

<style scoped>
.green-button {
  background-color: green;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}
</style>
