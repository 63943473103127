<template>
    <h1 style="max-width: 40ch; margin-left: 30%; font-family: 'Times New Roman', Times, serif; font-size: 36px;">
        Comprehensive Research on Druggable Protein: From PSSM to Pretrained Models
    </h1>
    <main id="about-page" class="offset-content">
        <p>
            In fact, i do not think the result is good for the Pharos dataset. And the GPT2 model also do not perform that well, this can partly becase of it is not so well trained.Anyway, results is here:
        </p>
        <table>
            <thead>
                <tr>
                    <th>Model</th>
                    <th>ACC</th>
                    <th>SN</th>
                    <th>SP</th>
                    <th>F1</th>
                    <th>MCC</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>DrugMiner</td>
                    <td>0.9210</td>
                    <td>0.9280</td>
                    <td>0.9134</td>
                    <td>0.9241</td>
                    <td>0.8417</td>
                </tr>
                <tr>
                    <td>GA-Bagging-SVM</td>
                    <td>0.9378</td>
                    <td>0.9286</td>
                    <td>0.9445</td>
                    <td>0.9358</td>
                    <td>0.8781</td>
                </tr>
                <tr>
                    <td>XGB-DrugPred</td>
                    <td>0.9486</td>
                    <td>0.9375</td>
                    <td>0.9574</td>
                    <td>0.9417</td>
                    <td>0.8900</td>
                </tr>
                <tr>
                    <td>DrugFinder</td>
                    <td>0.9498</td>
                    <td>0.9633</td>
                    <td>0.9683</td>
                    <td>0.9460</td>
                    <td>0.8996</td>
                </tr>
                <tr>
                    <td>Modified GPT-2</td>
                    <td>0.9282</td>
                    <td>0.9332</td>
                    <td>0.9224</td>
                    <td>0.9332</td>
                    <td>0.8556</td>
                </tr>
                <tr>
                    <td>Fine-tunned ESM-2 with CapsNet</td>
                    <td>0.9511</td>
                    <td>0.9683</td>
                    <td>0.9691</td>
                    <td>0.9512</td>
                    <td>0.9011</td>
                </tr>
            </tbody>
        </table>
    </main>
</template>

<script>
export default {
    name: 'AboutPage'
}
</script>

<style scoped>
.offset-content {
    margin-left: 25%;
    font-family: 'Times New Roman', Times, serif;
    text-align: justify;
}

.offset-content p {
    word-wrap: break-word;
    max-width: 70ch;
    font-size: 24px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

table, th, td {
    border: 1px solid black;
}

th, td {
    padding: 10px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
}
</style>
